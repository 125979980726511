import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Button,
  Span,
  Textarea,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Text Case Converter",
    link: "/text-case-converter/",
  },
]
const seeAlsoArray = [
  "/image-to-text",
  "/text-to-array-conversion",
  "/text-to-csv-conversion",
  "/text-emoticons-face-generator",
]

function TextCaseConverter(props) {
  let [outputArea, setoutputArea] = useState("")
  let [inputArea, setinputArea] = useState("")

  function inputAreaC(e) {
    setinputArea(e.target.value)
  }
  String.prototype.toTitleCase = function () {
    var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i
    return this.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function (
      match,
      index,
      title
    ) {
      if (
        index > 0 &&
        index + match.length !== title.length &&
        match.search(smallWords) > -1 &&
        title.charAt(index - 2) !== ":" &&
        (title.charAt(index + match.length) !== "-" ||
          title.charAt(index - 1) === "-") &&
        title.charAt(index - 1).search(/[^\s-]/) < 0
      ) {
        return match.toLowerCase()
      }
      if (match.substr(1).search(/[A-Z]|\../) > -1) {
        return match
      }
      return match.charAt(0).toUpperCase() + match.substr(1)
    })
  }
  function isAlphaNumeric(char) {
    let code = char.charCodeAt(0)
    if (
      !(code > 47 && code < 58) &&
      !(code > 64 && code < 91) &&
      !(code > 96 && code < 123)
    ) {
      return false
    }
    return true
  }
  function upperbtn() {
    var input = inputArea
    setoutputArea(input.toUpperCase())
  }

  function lowerbtn() {
    var input = inputArea
    setoutputArea(input.toLowerCase())
  }

  function sentencebtn() {
    var input = inputArea.toLowerCase()
    var output = ""
    var newSentence = true
    for (var i = 0; i < input.length; i++) {
      if (
        (newSentence && isAlphaNumeric(input[i])) ||
        (input[i] === "i" &&
          !isAlphaNumeric(input[i - 1]) &&
          !isAlphaNumeric(input[i + 1]))
      ) {
        output += input[i].toUpperCase()
        newSentence = false
      } else {
        output += input[i]
      }
      if (
        input[i] === "." ||
        input[i] === "?" ||
        input[i] === "!" ||
        input[i] === ":"
      ) {
        newSentence = true
      }
    }
    setoutputArea(output)
  }
  function properbtn() {
    var input = inputArea
    var output = ""
    var newWord = true
    for (var i = 0; i < input.length; i++) {
      if (newWord) output += input[i].toUpperCase()
      else output += input[i]
      if (input[i] === " ") newWord = true
      else newWord = false
    }
    setoutputArea(output)
  }
  function titlebtn() {
    var input = inputArea
    var lines = input.split("\n")
    var output = ""
    for (var i = 0; i < lines.length; i++) {
      if (i !== 0) output += "\n"
      output += lines[i].toTitleCase()
    }
    setoutputArea(output)
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Text Case Converter, Case Conversion"
        description="Text Case Converter, Case Conversion, Simple Convert Text To Upper case, Lower case, Sentence case, Proper case And Title case, step by step Text Case Conversion."
        keywords={[
          "Text Case Converter,Text Case translator,Text Case, Text Case Format,convert Text Case, Text Case convertion, text to Upper case, text to Lower case, text to Sentence case, text to Proper, text to Title case.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Text Case Converter</Title>
        <SubTitle>
          Convert Text To Upper case, Lower case, <br />
          Sentence case, Proper case And Title case .
        </SubTitle>

        <Span>Paste Your Text Here:</Span>
        <Textarea
          value={inputArea}
          onChange={inputAreaC}
          placeholder="Input text"
        ></Textarea>
        <br />
        <FlexDiv
          displayDiv="flex"
          flexWrap="wrap"
          justifyContent="center"
          className="mb-2"
        >
          <Button
            className="mb-2"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={upperbtn}
          >
            Upper case
          </Button>

          <Button
            className="mb-2"
            type="button"
            borderColor="#1da1f2"
            color="white"
            backgroundColor="#1da1f2"
            hoverColor="#1da1f2"
            hoverBorderColor="#1da1f2"
            onClick={lowerbtn}
          >
            Lower case
          </Button>
          <Button
            className="mb-2"
            borderColor="#ea4336"
            color="#ea4336"
            backgroundColor="white"
            hoverBorderColor="#ea4336"
            hoverBackgroundColor="#ea4336"
            hoverColor="white"
            onClick={sentencebtn}
          >
            Sentence case
          </Button>
          <Button
            className="mb-2"
            borderColor="#F55A34"
            color="white"
            backgroundColor="#F55A34"
            hoverColor="#F55A34"
            hoverBorderColor="#F55A34"
            hoverBackgroundColor="white"
            onClick={properbtn}
          >
            Proper case
          </Button>

          <Button
            className="mb-2"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={titlebtn}
          >
            Title case
          </Button>
        </FlexDiv>

        <Textarea
          value={outputArea}
          readOnly
          placeholder="Output text"
        ></Textarea>

        <br />
        <h3>Text Case Format</h3>
        <p>
          Enter your text in the top input above and click the options button
          you want to transform into.
          <br />
          Options: Upper case, Lower case, Sentence case, Proper case, And Title
          case, Your text will be converted and presented in the bottom input.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default TextCaseConverter
